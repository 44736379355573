<template>
    <div class="cbox">
        <div class="headbar">
            <div style="float: right">



                <el-select v-model="searchForm.enter_type" clearable placeholder="" size="small"
                    style="width:160px;margin-left:10px">
                    <el-option label="全部类型" value></el-option>
                    <el-option :label="item.enter_type" v-for="(item, idx) in TypeList" :key="idx"
                        :value="item.enter_type"></el-option>

                </el-select>
                <el-select v-model="searchForm.enter_hy" clearable placeholder="" size="small"
                    style="width:160px;margin-left:10px">
                    <el-option label="全部行业" value></el-option>
                    <el-option :label="item.enter_hy" v-for="(item, idx) in HyList" :key="idx"
                        :value="item.enter_hy"></el-option>

                </el-select>


                <el-input placeholder="企业名称关键字" v-model="searchForm.enter_name" size="small" class="input-with-select"
                    style="width:150px;margin-left: 10px" clearable></el-input>


                <el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
                    type="primary">搜索</el-button>
                <el-button v-if="TargetPmid" icon="el-icon-refresh" style="margin-left:10px" size="small"
                    @click="tongbuData">同步数据</el-button>

                <el-button icon="el-icon-upload" style="margin-left:10px" size="small"
                    @click="dialogshow1 = true">导入企业</el-button>

                <el-button icon="el-icon-upload" style="margin-left:10px" size="small"
                    @click="dialogshow2 = true">导入企业资料</el-button>

            </div>
            <div style="padding-top: 14px">
                <div style=" width: 200px;  margin-top:-10px;">
                    <div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
                        <img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
                    </div>
                    <div style="margin-left: 50px; color:#535353; font-size:20px !important">企业列表</div>
                </div>

            </div>
        </div>
        <div class="bcontent">
            <div style="display:flex">
                <div :class="['tpitem', searchForm.tjType == 'all' ? 'tpckd' : '']" @click="filterData('all')">
                    <div class="title">企业总数</div>
                    <div class="count">{{ Tongji.total }}</div>
                </div>

                <div :class="['tpitem', searchForm.tjType == 'guoyou' ? 'tpckd' : '']" @click="filterData('guoyou')">
                    <div class="title">国有企业</div>
                    <div class="count">{{ Tongji.guoyou }}</div>
                </div>
                <div :class="['tpitem', searchForm.tjType == 'siying' ? 'tpckd' : '']" @click="filterData('siying')">
                    <div class="title">私营企业</div>
                    <div class="count">{{ Tongji.siyou }}</div>
                </div>
                <div :class="['tpitem', searchForm.tjType == 'shinei' ? 'tpckd' : '']" @click="filterData('shinei')">
                    <div class="title">市内企业</div>
                    <div class="count">{{ Tongji.city_in }}</div>
                </div>
                <div :class="['tpitem', searchForm.tjType == 'shiwai' ? 'tpckd' : '']" @click="filterData('shiwai')">
                    <div class="title">市外企业</div>
                    <div class="count">{{ Tongji.city_out }}</div>
                </div>
                <div :class="['tpitem', searchForm.tjType == 'shengnei' ? 'tpckd' : '']" @click="filterData('shengnei')">
                    <div class="title">省内企业</div>
                    <div class="count">{{ Tongji.pro_in }}</div>
                </div>
                <div :class="['tpitem', searchForm.tjType == 'shengwai' ? 'tpckd' : '']" @click="filterData('shengwai')">
                    <div class="title">省外企业</div>
                    <div class="count">{{ Tongji.pro_out }}</div>
                </div>

                <div :class="['tpitem', searchForm.tjType == 'qita' ? 'tpckd' : '']" @click="filterData('qita')">
                    <div class="title">企业500强</div>
                    <div class="count">{{ Tongji.bz_count }}</div>
                </div>

                <div :class="['tpitem', searchForm.tjType == 'noyyzz' ? 'tpckd' : '']" @click="filterData('noyyzz')">
                    <div class="title">无营业执照</div>
                    <div class="count">{{ Tongji.noyyzz }}</div>
                </div>

                <div :class="['tpitem', searchForm.tjType == 'chufa' ? 'tpckd' : '']" @click="filterData('chufa')">
                    <div class="title">风险企业</div>
                    <div class="count">{{ Tongji.chufa_count }}</div>
                </div>



            </div>

            <div>
                <el-table :data="tableData" row-key="id" border stripe>
                    <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                    <el-table-column label="企业名称" prop="enter_name" width="150" align="center">
                        <template slot-scope="scope">
                            <span @click="showChufa(scope.row)" v-if="scope.row.chufa"
                                style="color:orangered;cursor: pointer;">{{ scope.row.enter_name }}<img
                                    :src="'./img/title-risk-icon_1.png'"
                                    style="width:20px;height:20px;vertical-align: middle;" /></span>
                            <span v-else>{{ scope.row.enter_name }}</span>
                        </template>

                    </el-table-column>
                    <el-table-column :label="year + '年在岗人数'" prop="zaigang" width="150" align="center"></el-table-column>
                    <el-table-column label="企业类型" prop="enter_type" width="150" align="center"></el-table-column>
                    <el-table-column label="企业地址" prop="enter_address" min-width="150" align="center"></el-table-column>
                    <el-table-column label="所属行业" prop="enter_hy" width="150" align="center"></el-table-column>
                    <el-table-column label="企业规模" prop="guimo" width="150" align="center"></el-table-column>
                    <el-table-column label="企业联系人" prop="lxr_name" width="150" align="center"></el-table-column>
                    <el-table-column label="联系人电话" prop="lxr_phone" width="150" align="center"></el-table-column>
                    <el-table-column label="营业执照" prop="yyzz" width="150" align="center">
                        <template slot-scope="scope">

                            <el-image v-if="scope.row.yyzz" style="width: 40px; height: 40px"
                                :src="scope.row.yyzz.indexOf('?') > 0 ? scope.row.yyzz + '&resize,w_100' : scope.row.yyzz + '?resize,w_100'"
                                fit="cover" :preview-src-list="[scope.row.yyzz]">

                            </el-image>
                            <span v-else>未上传</span>
                        </template>

                    </el-table-column>
                    <el-table-column label="企业状态" prop="regStatus" width="100" align="center"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="viewInfo(scope.row)" type="text" size="small">查看</el-button>

                            <el-button @click="deleteRow(scope.row)" type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div style="text-align:right;padding-top: 10px;">
                    <el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
                        :page-size="page.per_page" :total="page.count" layout="total, prev, pager, next"></el-pagination>
                </div>


            </div>

        </div>
        <infoDialog v-if="dialogShow" :id="ckdID"></infoDialog>


        <el-dialog class="cus_dialog " title="企业信息导入" :visible.sync="dialogshow1" :close-on-click-modal="false"
            width="600px" height="30%">
            <div style="line-height: 50px;display: inline-block;">
                <a href="/xls/企业导入模板.xlsx" target="_blank">企业导入模板下载</a>
            </div>
            <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
                :on-success="uploadFiles" :show-file-list="false" name="image">
                <el-button icon="el-icon-upload" type="primary" style="margin-left:100px" size="small">导入企业信息</el-button>
            </el-upload>

        </el-dialog>



        <el-dialog class="cus_dialog " title="企业信息导入" :visible.sync="dialogshow2" :close-on-click-modal="false"
            width="600px" height="30%">
            <div>
                <span
                    style="color: orangered; margin-right: 20px;">请上传【.zip】格式的压缩文件,其中的文件名称为【类型+企业组织代码（营业执照/税号）】！企业资料文件包括（营业执照照片，校企合作照片）</span>

            </div>
            <div>
                <img :src="'./img/ziptip.png'" style="width: 100%;" v-viewer />
            </div>

            <div style=" margin-top: 20px; text-align: right;">
                <div style="float: left;">
                    <a href="http://down.360safe.com/360zip_setup_4.0.0.1390.exe" target="_blank">下载zip安装包</a>
                </div>
                <el-upload style="display: inline-block;" action="" :http-request="uploadTemp" :on-success="uploadPics"
                    :on-progress="uploadProcess" :show-file-list="false" name="image">
                    <el-button size="small" icon="el-icon-upload2" type="primary">导入企业资料</el-button>
                </el-upload>
            </div>

            <div style="margin-top: 20px; height: 10px; border-radius: 5px; background-color: gainsboro;">
                <div style="height: 100%; border-radius: 5px; background-color: orangered;"
                    :style="{ width: percent + '%' }"></div>
            </div>
            <div style="text-align: center;font-size: 18px;" id="process">{{ percent }}%,上传完成前请勿刷新页面</div>

        </el-dialog>


        <el-dialog class="cus_dialog " title="企业风险" :visible.sync="dialogFengxian" :close-on-click-modal="false"
            width="80%" height="30%">

            <el-table :data="ChufaData" row-key="id" border stripe>
                <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                <el-table-column label="风险信息" prop="reason" min-width="150" align="center"></el-table-column>
                <el-table-column label="处理结果" prop="content" min-width="150" align="center"></el-table-column>
                <el-table-column label="处理部门" prop="departmentName" width="150" align="center"></el-table-column>
                <el-table-column label="回执号" prop="punishNumber" width="x" align="center"></el-table-column>
                <el-table-column label="发生日期" prop="decisionDate" width="150" align="center"></el-table-column>
              

            </el-table>

        </el-dialog>


    </div>
</template>

<script>
import infoDialog from './com/enterInfo.vue'

export default {
    components: {
        infoDialog

    },
    data() {
        return {
            dialogShow: false,
            dialogshow1: false,
            dialogshow2: false,
            dialogFengxian: false,
            searchForm: {
                tpmid: "",
                enter_name: "",
                enter_type: "",
                enter_hy: "",
                tjType: ""
            },
            TargetPmid: 0,
            SchoolList: [],
            TypeList: [],
            HyList: [],
            tableData: [],
            page: {
                count: 0,
                current_page: 1,
                per_page: 20,
                total_page: 0
            },
            Tongji: {
                total: 0,//总数
                bz_count: 0,//标注企业数
                tplist: [],
            },
            ckdID: 0,
            percent: 0,
            year: sessionStorage.getItem("year"),
            ChufaData: []
        }
    },
    mounted() {

        this.getTypeList()
        this.getHyList()
        this.getDataList()
        this.getTargetPmid()

    },
    methods: {
        filterData(e) {
            this.searchForm.tjType = e
            this.page.current_page = 1
            this.getDataList()
        },
        getTongji(data) {
            this.$http.post("/api/enterprise_tongji", data).then(res => {
                this.Tongji = res.data
            })

        },
        getTargetPmid() {
            this.$http.post("/api/sch_qsx_target").then(res => {
                if (res.data && res.data.qsx_pmid) {
                    this.TargetPmid = res.data.qsx_pmid
                }
            })

        },
        getTypeList() {
            this.$http.post("/api/enter_type_list").then(res => {
                this.TypeList = res.data
            })
        },
        getHyList() {
            this.$http.post("/api/enter_hy_list").then(res => {
                this.HyList = res.data
            })
        },
        getDataList() {
            let data = {
                page: this.page.current_page,
                keyword: this.searchForm.enter_name,
                enter_type: this.searchForm.enter_type,
                enter_hy: this.searchForm.enter_hy,
                school_id: this.searchForm.school_id,
                showloading: true,
                tjtype: this.searchForm.tjType
            }
            this.$http.post("/api/enterprise_list", data).then(res => {
                this.tableData = res.data.data
                this.page = res.data.page
            })

            this.getTongji(data)
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getDataList();
        },
        viewInfo(e) {
            this.ckdID = e.id
            this.dialogShow = true
        },
        closeEnter() {
            this.dialogShow = false
        },
        tongbuData() {
            this.$http.post("/api/tongbu_enterpirse", { loading: true, tpmid: this.TargetPmid }).then(res => {
                this.$message.success("同步成功")
                this.page.current_page = 1;
                this.getDataList();
            })
        },
        uploadFiles(e) {
            this.$http.post("/api/import_enterprisexls", {
                url: e.src
            }).then(res => {

                this.getDataList()
                this.$message.success("导入成功")
                if (res.data.msg.length > 0) {
                    let html = ''
                    for (let msg of res.data.msg) {
                        html += "<div style='font-size:12px'>" + msg.enter_name + ":" + msg.msg + "</div>"
                    }
                    this.$alert(html, '未导入数据', {
                        dangerouslyUseHTMLString: true
                    });
                }
                this.dialogshow1 = false


            })
        },

        uploadPics(e) {
            this.$http.post("/api/upload_zip_enterprise", {
                url: e.src
            }).then(res => {


                this.$message.success("正在解析文件中，可能需要几分钟~")

                this.dialogshow2 = false


            })
        },
        deleteRow(e) {

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/enter_delete", { id: e.id }).then(res => {
                    this.getDataList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });

                })
            }).catch(() => {

            });




        },
        uploadProcess(e) {
            this.percent = parseInt(e.loaded / e.total * 10000) / 100
        },
        showChufa(row) {
            console.log(row)
            this.ChufaData = JSON.parse(row.chufa)
            this.dialogFengxian = true
            console.log(this.ChufaData)
        }
    }
}
</script>
<style scoped>
.tpitem {
    border: 1px solid #ccc;
    margin: 5px;
    flex-grow: 1;
    padding: 10px;
    border-radius: 8px;
    background-image: url(../../../public/img/btnbg.png);
    background-size: 100% 100%;
}

.tpitem:nth-child(1) {
    margin-left: 0;
}

.tpitem:nth-last-child() {
    margin-right: 0;
}

.tpitem .title {}

.tpitem .count {
    font-size: 20px;
}

.tpckd {
    background-color: #66b1ff;
    color: #fff;
}</style>
