<template>
    <div>
        <el-dialog class="cus_dialog " title="企业详情" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="80%" height="90%">

            <el-tabs v-model="activeName">
                <el-tab-pane label="企业详情" name="first"></el-tab-pane>
                <el-tab-pane label="校企大事记" name="second"></el-tab-pane>

            </el-tabs>



            <el-row v-show="activeName == 'first'" :gutter="20">

                <el-col :span="7">

                    <el-card class="box-card">
                        <div style="min-height:60vh">

                            <el-form v-model="EnterInfo">
                                <el-row>
                                    <el-tag effect="plain">
                                        企业基础信息
                                    </el-tag>
                                    <el-tag effect="plain" style="margin-left:5px;cursor: pointer;" @click="refreshTyc"
                                        title="同步工商信息">
                                        <i class="el-icon-refresh"></i>
                                    </el-tag>
                                    <el-button style="float:right" v-if="saveBtnShow" size="mini" type="primary"
                                        icon="el-icon-check" @click="saveEnterInfo">保存</el-button>
                                </el-row>
                                <el-row>
                                    <el-form-item label="企业名称:" label-width="80px" style="margin-bottom: 0;">
                                        <el-input style="width:90%" @input="showSave" size="small"
                                            v-model="EnterInfo.enter_name"></el-input>
                                    </el-form-item>

                                </el-row>
                                <el-row>
                                    <el-form-item label="组织代码:" label-width="80px" style="margin-bottom: 0;">
                                        <el-input style="width:90%" @input="showSave" size="small"
                                            v-model="EnterInfo.enter_num"></el-input>
                                    </el-form-item>
                                </el-row>
                                <el-row>

                                    <el-form-item label="企业性质:" label-width="80px" style="margin-bottom: 0;">
                                        <el-select size="small" v-model="EnterInfo.enter_type" @change="showSave"
                                            style="width: 90%;">
                                            <el-option v-for="(item) in entTP" :key="item" :label="item"
                                                :value="item"></el-option>
                                        </el-select>
                                    </el-form-item>

                                </el-row>
                                <el-row>

                                    <el-form-item label="企业规模:" label-width="80px" style="margin-bottom: 0;">
                                        <el-select size="small" v-model="EnterInfo.guimo" @change="showSave"
                                            style="width: 90%;">
                                            <el-option label="10人以下" value="10人以下"></el-option>
                                            <el-option label="10-50人" value="10-50人"></el-option>
                                            <el-option label="50-100人" value="50-100人"></el-option>
                                            <el-option label="100-200人" value="100-200人"></el-option>
                                            <el-option label="200人以上" value="200人以上"></el-option>
                                        </el-select>
                                    </el-form-item>

                                </el-row>
                                <el-row>
                                    <el-form-item label="所属行业:" label-width="80px" style="margin-bottom: 0;">
                                        <el-input @input="showSave" :autosize="{ minRows: 2, maxRows: 100 }"
                                            style="width:90%" size="small" v-model="EnterInfo.enter_hy"></el-input>
                                        <!-- <el-cascader ref="firstCascader" :options="entHY" :props="defaultProps"
                                            v-model="EnterInfo.enter_hy" @change="showSave" :show-all-levels="false"
                                            clearable placeholder="请选择" filterable style="width: 90%;" size="small">
                                        </el-cascader> -->
                                    </el-form-item>
                                </el-row>
                                <el-row>

                                    <el-form-item label="企业简介:" label-width="80px" style="margin-bottom: 0;">
                                        <el-input type="textarea" @input="showSave" :autosize="{ minRows: 2, maxRows: 100 }"
                                            style="width:90%" size="small" v-model="EnterInfo.enter_desc"></el-input>
                                    </el-form-item>


                                </el-row>
                                <el-row>

                                    <el-form-item label="所在城市:" label-width="80px" style="margin-bottom: 0;">
                                        <el-input style="width:90%" @input="showSave" size="small"
                                            v-model="EnterInfo.enter_city"></el-input>
                                    </el-form-item>


                                </el-row>
                                <el-row>

                                    <el-form-item label="企业标注:" label-width="80px" style="margin-bottom: 0;">


                                        <el-select size="small" v-model="EnterInfo.remark" @change="showSave"
                                            style="width: 90%;">
                                            <el-option v-for="(item, idx) in BiaoZhuList" :key="idx" :label="item.bz_name"
                                                :value="item.bz_name"></el-option>

                                        </el-select>

                                    </el-form-item>


                                </el-row>
                                <el-row>

                                    <el-form-item label="企业地址:" label-width="80px" style="margin-bottom: 0;">
                                        <el-input style="width:90%" @input="showSave" size="small"
                                            v-model="EnterInfo.enter_address"></el-input>
                                    </el-form-item>


                                </el-row>


                                <qqmap v-if="EnterInfo.enter_address"
                                    :address="EnterInfo.enter_city + EnterInfo.enter_address"></qqmap>


                            </el-form>

                        </div>

                    </el-card>

                </el-col>
                <el-col :span="10">

                    <el-card class="box-card">
                        <div style="min-height:60vh">
                            <el-tag effect="plain">
                                校企合作信息
                            </el-tag>
                            <div style="height:10px"></div>
                            <table class="dttable" style="text-align: center;">
                                <tr>
                                    <td class="tdheadersml" style="width:auto">时间</td>
                                    <td class="tdheadersml" style="width:auto">实习<br />人数</td>
                                    <td class="tdheadersml" style="width:auto">最高<br />工资</td>
                                    <td class="tdheadersml" style="width:auto">最低<br />工资</td>
                                    <td class="tdheadersml" style="width:auto">平均<br />工资</td>
                                    <td class="tdheadersml" style="width:auto">对口率</td>
                                    <td class="tdheadersml" style="width:auto">社保<br />缴纳<br />情况</td>
                                </tr>
                                <tr v-for="(item, index) in chartData.max_salary" :key="index">
                                    <td>{{ item.year }}</td>
                                    <td>{{ chartData.shixi[index].n }}</td>
                                    <td>{{ chartData.max_salary[index].n }}</td>
                                    <td>{{ chartData.min_salary[index].n }}</td>
                                    <td>{{ chartData.avg_salary[index].n }}</td>
                                    <td>{{ chartData.duikou[index].n }}</td>
                                    <td>{{ chartData.shebao[index].n }}</td>


                                </tr>
                                <tr v-if="chartData.max_salary && chartData.max_salary.length > 0">
                                    <td></td>
                                </tr>
                                <tr v-else>
                                    <td colspan="7">暂无数据</td>
                                </tr>
                            </table>



                            <div id="yjChart" style="height:250px">

                            </div>

                            <div id="yjChart2" style="height:250px">

                            </div>


                        </div>

                    </el-card>
                </el-col>
                <el-col :span="7">
                    <el-card class="box-card">
                        <div style="min-height:60vh">
                            <el-tag effect="plain">
                                校企合作信息
                            </el-tag>


                            <div id="yjChart3" style="height:250px">

                            </div>

                            <el-tag effect="plain">
                                校企合作协议：
                            </el-tag>

                            <div style="padding:10px 0">
                                <div>
                                    <div class="imageitem" v-for="(img, i) in EnterInfo.files" :key="i"
                                        v-if="img.type == 'image'">
                                        <el-image style="width: 100%; height: 100%" :src="img.src" fit="cover"
                                            :preview-src-list="[img.src]">
                                            <div>asdf</div>
                                        </el-image>
                                        <div class="remove" @click.stop="removeImage(i)">
                                            <i class="el-icon-remove"></i>
                                        </div>
                                    </div>

                                    <div class="fileitem" v-for="(img, i) in EnterInfo.files" :key="i"
                                        v-if="img.type != 'image'">
                                        <a :href="EnterInfo.yyzz" target="_blank">{{ img.fileName }}</a>
                                        <div class="remove" @click.stop="removeImage(i)">
                                            <i class="el-icon-remove"></i>
                                        </div>
                                    </div>


                                </div>
                                <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadOss"
                                    :on-success="handleUpload1" :show-file-list="false" name="image">

                                    <el-button icon="el-icon-plus" circle></el-button>
                                </el-upload>
                            </div>

                            <el-tag effect="plain">
                                营业执照：
                            </el-tag>

                            <div style="padding:10px 0" v-if="EnterInfo.yyzz">
                                <div>
                                    <div class="imageitem"
                                        v-if="EnterInfo.yyzz.indexOf('.jpg') > 0 || EnterInfo.yyzz.indexOf('.jpeg') > 0 || EnterInfo.yyzz.indexOf('.png') > 0 || EnterInfo.yyzz.indexOf('.webp') > 0">
                                        <el-image style="width: 100%; height: 100%" :src="EnterInfo.yyzz" fit="cover"
                                            :preview-src-list="[EnterInfo.yyzz]">

                                        </el-image>
                                        <div class="remove" @click.stop="removeYYZZ()">
                                            <i class="el-icon-remove"></i>
                                        </div>
                                    </div>

                                    <div class="fileitem" v-else>
                                        <a :href="EnterInfo.yyzz" target="_blank"> 点击下载营业执照文件</a>
                                        <div class="remove" @click.stop="removeYYZZ()">
                                            <i class="el-icon-remove"></i>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div style="padding-top:10px">
                                <el-upload v-if="!EnterInfo.yyzz" style="display: inline-block;" :limit="1" action
                                    :multiple="true" :http-request="uploadOss" :on-success="handleUpload2"
                                    :show-file-list="false" name="image">

                                    <el-button icon="el-icon-plus" circle></el-button>
                                </el-upload>
                            </div>

                        </div>

                    </el-card>
                </el-col>
            </el-row>

            <el-row v-show="activeName == 'second'" :gutter="20">
                <el-col :span="15">
                    <el-card class="box-card">
                        <img :src="'/img/licheng.jpg'" style="width:100%" />
                        <!-- <el-row>
                            <div style="display:flex; position:relative;">
                                <div style="width:49%">

                                    <div class="sjLeft" :style="{ opacity: i % 2 == 0 ? 1 : 0 }"
                                        v-for="(item, i) in bigSJList" :key="i">
                                        <div class="sjdate">{{ item.sj_date }}</div>
                                        <div class="sjdesc" @click="editSJ(item)">
                                            {{ item.sj_desc }}
                                        </div>
                                        <div class="linepointL">
                                            <i class="el-icon-orange"></i>
                                        </div>

                                        <div class="imageitem3" v-for="(img, i) in item.files" :key="i"
                                            v-if="img.type == 'image'">
                                            <el-image style="width: 100%; height: 100%" :src="img.src" fit="cover"
                                                :preview-src-list="[img.src]">

                                            </el-image>

                                        </div>
                                        <div class="sjmask" v-if="i % 2 == 1"></div>

                                    </div>
                                </div>
                                <div
                                    style="width:0px; height: 100%;border-right:4px solid #409eff;position: absolute; left: 50%;">

                                </div>
                                <div style="width:49%;margin-left: 2%;">
                                    <div class="sjright" :style="{ opacity: i % 2 == 1 ? 1 : 0 }"
                                        v-for="(item, i) in bigSJList" :key="i">
                                        <div class="sjdate">{{ item.sj_date }}</div>
                                        <div class="sjdesc" @click="editSJ(item)">
                                            {{ item.sj_desc }}
                                        </div>
                                        <div class="linepointR">
                                            <i class="el-icon-orange"></i>
                                        </div>
                                        <div class="imageitem" v-for="(img, i) in item.files" :key="i"
                                            v-if="img.type == 'image'">
                                            <el-image style="width: 100%; height: 100%" :src="img.src" fit="cover"
                                                :preview-src-list="[img.src]">

                                            </el-image>

                                        </div>
                                        <div class="sjmask" v-if="i % 2 == 0"></div>
                                    </div>
                                </div>


                            </div>

                        </el-row> -->
                    </el-card>
                </el-col>
                <el-col :span="9">
                    <el-card class="box-card">
                        <div>
                            <el-tag effect="plain">
                                编辑校企大事记
                            </el-tag>
                            <el-button style="float:right" size="small" icon="el-icon-plus"
                                @click="addNewSJ">添加新事件</el-button>
                        </div>


                        <el-form v-model="bigSJ">

                            <el-row>
                                <el-form-item label="发生日期:" label-width="80px" style="margin-bottom: 0;">
                                    <el-date-picker size="small" v-model="bigSJ.sj_date" value-format="yyyy-MM-dd"
                                        type="date" placeholder="选择日期">
                                    </el-date-picker>

                                </el-form-item>

                            </el-row>


                            <el-row>

                                <el-form-item label="事件简介:" label-width="80px" style="margin-bottom: 0;">
                                    <el-input type="textarea" @input="showSave" :autosize="{ minRows: 10, maxRows: 100 }"
                                        style="width:90%" size="small" v-model="bigSJ.sj_desc"></el-input>
                                </el-form-item>


                            </el-row>

                            <el-row>
                                <el-form-item label="图片展示:" label-width="80px" style="margin-bottom: 0;">



                                    <div style="padding:10px 0">
                                        <div>
                                            <div class="imageitem" v-for="(img, i) in bigSJ.files" :key="i">
                                                <el-image style="width: 100%; height: 100%" :src="img.src" fit="cover"
                                                    :preview-src-list="[img.src]">

                                                </el-image>
                                                <div class="remove" @click.stop="removeImage3(i)">
                                                    <i class="el-icon-remove"></i>
                                                </div>
                                            </div>




                                        </div>
                                        <el-upload style="display: inline-block;" action :multiple="true"
                                            :http-request="uploadOss" :on-success="handleUpload3" :show-file-list="false"
                                            name="image">

                                            <el-button icon="el-icon-plus" circle></el-button>
                                        </el-upload>
                                    </div>

                                </el-form-item>

                            </el-row>

                        </el-form>


                        <div style="text-align:right">


                            <el-button size="small" type="primary" icon="el-icon-check" @click="saveBigSJ">保存</el-button>

                        </div>

                        <div></div>
                    </el-card>
                </el-col>
            </el-row>

        </el-dialog>


    </div>
</template>

<script>
import qqmap from "./map.vue"
import entbase from "./enterBase"
export default {
    props: ["id"],
    components: {
        qqmap
    },
    data() {
        return {
            dialogshow: true,
            saveBtnShow: false,
            EnterInfo: {},
            activeName: "first",
            tongjiData: [],
            BiaoZhuList: [],
            entHY: entbase.enterHy,
            entTP: entbase.enterType,
            defaultProps: {
                label: 'name',
                value: 'name',
                children: 'children',
                emitPath: false,
                checkStrictly: true
            },
            bigSJList: [],
            bigSJ: {
                sj_date: "",
                sj_desc: "",
                files: [],
            },
            chartData: {}

        }
    },
    mounted() {
        this.getInfo()
        this.getBiaozhu()
        let year = new Date().getFullYear();
        for (let i = year; i > year - 4; i--) {
            let data = {
                year: year,
                c1: parseInt(Math.random() * 100),
                c2: parseInt(Math.random() * 100),
                c3: parseInt(Math.random() * 100),
                c4: parseInt(Math.random() * 100),
                c5: parseInt(Math.random() * 100),
                c6: parseInt(Math.random() * 100),
                c7: parseInt(Math.random() * 100),
            }
            this.tongjiData.push(data)
        }


    },
    methods: {
        showSave() {
            this.saveBtnShow = true
        },
        getInfo() {
            this.$http.post("/api/enterprise_info", { id: this.id }).then(res => {
                if (res.data.files) {
                    res.data.files = JSON.parse(res.data.files)
                } else {
                    res.data.files = []
                }
                this.EnterInfo = res.data

                this.getBigSJ()
                this.getTongji()
            })
        },
        getBiaozhu() {
            this.$http.post("/api/enter_biaozhu_list").then(res => {
                this.BiaoZhuList = res.data
            })
        },
        getTongji() {
            this.$http.post("/api/enterone_jiuye_tongji", { id: this.id }).then(res => {
                this.chartData = res.data
                setTimeout(() => {
                    this.initChart()
                    this.initChart2()
                    this.initChart3()
                }, 300);
            })
        },
        closeDialog() {

            this.$$parent(this, "closeEnter")

        },

        initChart() {


            let xdata = [], years = [];
            for (let i = 0; i < this.chartData.shixi.length; i++) {
                xdata.push(this.chartData.shixi[i].n)
                years.push(this.chartData.shixi[i].year)
            }
            // xdata = [2, 1, 4, 7, 3, 2], years = [2021, 2022, 2023, 2024, 2025, 2026];
            let option = {
                title: {
                    text: "年度实习人数统计图",
                    left: "center"
                },
                tooltip: {
                    trigger: 'axis'


                },
                xAxis: {
                    type: 'category',
                    data: years
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: xdata,
                        type: 'line',
                        color: "#409eff"
                    }
                ]
            };
            var yjChart = this.$echarts.init(document.getElementById('yjChart'));
            yjChart.setOption(option);

        },
        initChart2() {

            let xdata = [], years = [];
            for (let i = 0; i < this.chartData.avg_salary.length; i++) {
                xdata.push(this.chartData.avg_salary[i].n)
                years.push(this.chartData.avg_salary[i].year)
            }
            // xdata = [2, 1, 4, 7, 3, 2], years = [2021, 2022, 2023, 2024, 2025, 2026];
            let option = {
                title: {
                    text: "年度实习平均工资统计图",
                    left: "center"
                },
                tooltip: {
                    trigger: 'axis'


                },
                xAxis: {
                    type: 'category',
                    data: years
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: xdata,
                        type: 'line',
                        color: "#409eff"
                    }
                ]
            };
            var yjChart = this.$echarts.init(document.getElementById('yjChart2'));
            yjChart.setOption(option);

        },
        initChart3() {

            let xdata = [], years = [],xdata2 = [] ;
            for (let i = 0; i < this.chartData.duikou.length; i++) {
                xdata.push(this.chartData.duikou[i].n)
                years.push(this.chartData.duikou[i].year)
            }
            for (let i = 0; i < this.chartData.shebao.length; i++) {
                xdata2.push(this.chartData.shebao[i].n)
               
            }
            // xdata = [2, 1, 4, 7, 3, 2], years = [2021, 2022, 2023, 2024, 2025, 2026];
            // xdata2= [4, 3, 3, 4, 1, 1];
            let option = {
                title: {
                    text: "年度实习社保和对口率统计图",
                    left: "center"
                },
                legend:{
                    data: ['对口人数', '社保人数']
                },
                tooltip: {
                    trigger: 'axis'


                },
                xAxis: {
                    type: 'category',
                    data: years
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: xdata,
                        type: 'line',
                        color: "#409eff"
                    },
                    {
                        data: xdata2,
                        type: 'line',
                        color: "#ff6666"
                    }

                ]
            };
            var yjChart = this.$echarts.init(document.getElementById('yjChart3'));
            yjChart.setOption(option);

        },
        saveEnterInfo() {
            console.log(this.EnterInfo)
            this.$http.post("/api/enterprise_edit", this.EnterInfo).then(res => {
                this.getInfo()
                this.$message.success("保存成功")
                this.saveBtnShow = false

            })


        },
        handleUpload1(e) {

            e.src = e.src.toLowerCase()
            if (e.src.indexOf(".jpg") > 0 || e.src.indexOf(".jpeg") > 0 || e.src.indexOf(".png") > 0 || e.src.indexOf(".webp") > 0) {
                e.type = "image"
            } else {
                e.type = "file"
            }
            if (!this.EnterInfo.files) {
                this.EnterInfo.files = []
            }
            this.EnterInfo.files.push(e)
            this.$http.post("/api/enterprise_edit", this.EnterInfo).then(res => {
                this.getInfo()
                this.$message.success("保存成功")
            })
        },
        removeImage(i) {
            this.$confirm('确认删除文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.EnterInfo.files.splice(i, 1)
                this.$http.post("/api/enterprise_edit", this.EnterInfo).then(res => {
                    this.getInfo()
                    this.$message.success("删除成功")
                })
            }).catch(() => {

            });
        },
        handleUpload2(e) {
            this.EnterInfo.yyzz = e.src
            this.$http.post("/api/enterprise_edit", this.EnterInfo).then(res => {
                this.getInfo()
                this.$message.success("保存成功")
            })
        },
        handleUpload3(e) {

            e.src = e.src.toLowerCase()
            if (e.src.indexOf(".jpg") > 0 || e.src.indexOf(".jpeg") > 0 || e.src.indexOf(".png") > 0 || e.src.indexOf(".webp") > 0) {
                e.type = "image"
            } else {
                e.type = "file"
            }
            this.bigSJ.files.push(e)

        },
        removeImage3(i) {
            this.$confirm('确认删除文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.bigSJ.files.splice(i, 1)

            }).catch(() => {

            });
        },
        removeYYZZ() {

            this.$confirm('确认删除营业执照文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.EnterInfo.yyzz = ""

                this.$http.post("/api/enterprise_edit", this.EnterInfo).then(res => {
                    this.getInfo()
                    this.$message.success("删除成功")
                })

            }).catch(() => {

            });



        },
        getBigSJ() {
            this.$http.post("/api/enter_bigsj_info", { enter_id: this.EnterInfo.id }).then(res => {
                for (let item of res.data) {
                    if (item.files) {
                        item.files = JSON.parse(item.files)
                    }
                }
                this.bigSJList = res.data
            })
        },
        addNewSJ() {
            this.bigSJ = {
                sj_date: "",
                sj_desc: "",
                files: []
            }
        },
        saveBigSJ() {
            this.bigSJ.enter_id = this.EnterInfo.id

            this.$http.post("/api/enter_bigsj_edit", this.bigSJ).then(res => {
                this.getBigSJ()
                this.$message.success("保存成功")
                this.addNewSJ()
            })
        },
        editSJ(item) {
            this.bigSJ = item
        },
        refreshTyc() {
            this.$http.post("/api/tyc_search", { enter_name: this.EnterInfo.enter_name }).then(res => {
                if (res.data.creditCode) {
                    this.EnterInfo.enter_type = res.data.companyOrgType
                    this.EnterInfo.guimo = res.data.staffNumRange
                    this.EnterInfo.enter_hy = res.data.industry
                    this.saveEnterInfo()


                }

            })
        }

    }

}

</script>

<style scoped>
.imageitem {
    border: 1px dotted #ccc;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 5px;
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.imageitem3 {
    border: 1px dotted #ccc;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 5px;
    width: 60px;
    height: 60px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.remove {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    color: orangered;
    cursor: pointer;

}

.fileitem {
    border: 1px dotted #ccc;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    vertical-align: top;
    position: relative;
    cursor: pointer;
}

.fileitem:hover {
    color: blue
}

.sjLeft {
    width: 60%;
    position: relative;
}

.linepointL {
    position: absolute;
    margin-left: 100%;
    width: 71%;
    height: 30px;
    border-top: 3px solid #409eff;
    text-align: right;
    z-index: 1;
    top: 10px;

}

.linepointL i {
    display: inline-block;
    font-size: 20px;
    color: #409eff;
    transform: translate(10px, -11px);
}

.sjright {
    width: 60%;
    margin-left: 40%;
    position: relative;
}


.linepointR {
    position: absolute;
    margin-left: -70%;
    width: 65%;
    height: 30px;
    border-top: 3px solid #409eff;
    text-align: left;
    z-index: 1;
    top: 10px;

}

.linepointR i {
    display: inline-block;
    font-size: 20px;
    color: #409eff;
    transform: translate(-10px, -11px);
}

.sjdesc {
    padding: 10px 0;
    cursor: pointer;
}

.sjmask {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    z-index: 2;
}
</style>